.icon-blank{
	background:url('icons/blank.gif') no-repeat center center;
}
.icon-add{
	background:url('icons/edit_add.png') no-repeat center center;
}
.icon-edit{
	background:url('icons/pencil.png') no-repeat center center;
}
.icon-clear{
	background:url('icons/clear.png') no-repeat center center;
}
.icon-remove{
	background:url('icons/edit_remove.png') no-repeat center center;
}
.icon-save{
	background:url('icons/filesave.png') no-repeat center center;
}
.icon-cut{
	background:url('icons/cut.png') no-repeat center center;
}
.icon-ok{
	background:url('icons/ok.png') no-repeat center center;
}
.icon-no{
	background:url('icons/no.png') no-repeat center center;
}
.icon-cancel{
	background:url('icons/cancel.png') no-repeat center center;
}
.icon-reload{
	background:url('icons/reload.png') no-repeat center center;
}
.icon-search{
	background:url('icons/search.png') no-repeat center center;
}
.icon-print{
	background:url('icons/print.png') no-repeat center center;
}
.icon-help{
	background:url('icons/help.png') no-repeat center center;
}
.icon-undo{
	background:url('icons/undo.png') no-repeat center center;
}
.icon-redo{
	background:url('icons/redo.png') no-repeat center center;
}
.icon-back{
	background:url('icons/back.png') no-repeat center center;
}
.icon-sum{
	background:url('icons/sum.png') no-repeat center center;
}
.icon-tip{
	background:url('icons/tip.png') no-repeat center center;
}
.icon-filter{
	background:url('icons/filter.png') no-repeat center center;
}
.icon-man{
	background:url('icons/man.png') no-repeat center center;
}
.icon-lock{
	background:url('icons/lock.png') no-repeat center center;
}
.icon-more{
	background:url('icons/more.png') no-repeat center center;
}


.icon-mini-add{
	background:url('icons/mini_add.png') no-repeat center center;
}
.icon-mini-edit{
	background:url('icons/mini_edit.png') no-repeat center center;
}
.icon-mini-refresh{
	background:url('icons/mini_refresh.png') no-repeat center center;
}

.icon-large-picture{
	background:url('icons/large_picture.png') no-repeat center center;
}
.icon-large-clipart{
	background:url('icons/large_clipart.png') no-repeat center center;
}
.icon-large-shapes{
	background:url('icons/large_shapes.png') no-repeat center center;
}
.icon-large-smartart{
	background:url('icons/large_smartart.png') no-repeat center center;
}
.icon-large-chart{
	background:url('icons/large_chart.png') no-repeat center center;
}
